.search-bar-container {
  display: flex;
  align-items: center;
  border: 1px solid #e7e6ea;
  height: 40px;
  width: 100%;
  padding: 10px 14px;
  background-color: white;
}
.search-bar-container:hover {
  border: 1px solid #b04aff;
}
.search-icon {
  height: 20px;
  margin-right: -40px;
}
.search-input {
  margin-left: 48px;
  width: 100%;
  border: none;
  height: 100%;
  font-size: 16px;
}
.search-input:focus {
  outline: none;
}
.search-input::placeholder {
  color: #cfcdd5;
}
.search-input-focus {
  border: 1px solid #b04aff;
}
