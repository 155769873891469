.greyRow {
  background-color: #F8F9FF;
}

.whiteRow {
  background-color: white;
}

.leftValue {
  font-size: 1rem;
  color: #0F072C !important;
  opacity: 1;
  width: 1.875rem;
}

.rightValue {
  font-size: 1rem;
  color: #0F072C !important;
  opacity: 0.6;
}

.users-container {
  margin-bottom: 2.5rem;
}

.categories-container {
  margin-bottom: 1rem;
}

.category-heading {
  font-size: 1.125rem;
}

.category-icon-svg-container {
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.category-icon-svg {
  width: 1rem;
  height: 1rem;
}

.description-container {
  margin-bottom: 2rem;
}

.link-text {
  color: #0F072C !important;
  opacity: 0.6;
  &:hover {
    color: #B04AFF !important;
    opacity: 1;
  }
}
