.data-grid-even-row-clickable {
  background-color: #f8f9ff;
  transition: padding-left 0.5s ease;
  &:hover {
    background-color: #CECDD4 !important;
    cursor: pointer;
    padding-left: 10px;
  }
  .MuiDataGrid-cell:focus {
    outline: none !important;;
  }
}
.data-grid-even-row-unclickable {
  background-color: #f8f9ff;
  .MuiDataGrid-cell:focus {
    outline: none !important;;
  }
}
.data-grid-odd-row-clickable {
  transition: padding-left 0.5s ease;
  &:hover {
    background-color: #CECDD4 !important;
    cursor: pointer;
    padding-left: 10px;
  }
  .MuiDataGrid-cell:focus {
    outline: none !important;;
  }
}
.data-grid-odd-row-unclickable {
  .MuiDataGrid-cell:focus {
    outline: none !important;;
  }
}
.available-user-over-allocated {
  background-color: #ffeff0;
}
