* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Lexend', sans-serif !important
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #e7e6e9;
}

*::-webkit-scrollbar-thumb {
    background-color: #666078;
}

*::-webkit-scrollbar-track-piece {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #e7e6e9;
}
