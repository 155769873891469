.comment-body {
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
  background-color: #ffffff;
  min-height: 40vh;
  max-height: 50vh;
  margin-left: -30px;
  margin-right: -30px;
}

@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");
.modal {
  font-family: "Lexend", sans-serif;
}

.comment-card {
  background-color: #EFDBFF;
  box-shadow: none;
  max-width: 370px;
  width: fit-content;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
}

.sender-card{
  background-color: #F7EDFF;
}

.comment-message {
  font-size: 16px;
  color: #6f6a80;
  word-wrap: break-word;
}

.comment-message-author {
  color: #0f072c;
  opacity: 0.4;
  font-size: 14px;
}

.comment-message-time {
  color: #0f072c;
  opacity: 0.4;
  font-size: 14px;
  align-self: flex-end;
}

.comment-container {
  align-self: flex-start;
  margin-bottom: 12px;
}

.comment-sender {
  align-self: flex-end;
}

.comment-model-container {
  display: flex;
  flex-direction: column;
}
.comment-modal-container {
  min-height: 70vh;
}

.comment-sender-background {
  background-color: rgba(176, 74, 255, 0.2);
}
.commentIcon {
  height: 24;
  width: 24;
  cursor: pointer;
}

.commentIcon:hover {
  transform: scale(1.1);
  opacity: 0.75;
}

.comment-modal-form {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.comment-form{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}