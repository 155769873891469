.select-box {
  height: 40px;
}
.error-feedback {
  color: #ff384f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin: 3px 14px 0 0px !important;
}
.disabled-feedback {
  color: #898b8f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin: 3px 14px 0 0px !important;
}
