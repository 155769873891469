.commentIcon {
    height: 24;
    width: 24;
    cursor: pointer;
  }
  
.commentIcon:hover {
    transform: scale(1.1);
    opacity: 0.75;
  }
.select-box {
    height: 35px;
  }
.severity-icon{
    width: 25px;
    height: 25px;
    padding-right: 5px;
    padding-top: 10px;
  }
.severity-icon-handling{
    width: 30px;
    height: 30px;
    padding-right: 7px;
    padding-bottom: 5px;
  }

  .shimmer-thumbnail-no-margin-bottom {
    margin-bottom: 0;
  }
