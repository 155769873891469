.invite-box {
  padding: 7px 16px;
  margin-right: 16px;
  width: 8.9rem;
  display: flex;
  justify-content: center;
}

.invite-button {
  padding: 7px 16px;
  margin-right: 16px;
  width: 8.9rem;
  display: flex;
  justify-content: center;
  text-transform: none;
  border-radius: 0;
}