.header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1.2rem;
}
.modalTitle {
  font-size: 1em;
  flex-grow: 1;
  color: #0f072c !important;
  font-size: 1.7em;
  font-weight: bold;
}
.username {
  color: #2684ff;
  font-weight: 600;
  font-size: 1.2em;
}
