.common-background-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
}
.common-layout-center-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.common-background-logo {
    width: 101.96px;
    z-index: 10;
    margin-top: 40px;
}
.common-background-grid {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    background-color: #150b3a;
    object-fit: cover;
}
.common-background-red-eclipse {
    position: absolute;
    z-index: -1;
    height: 941px;
    width: 899px;
    right: -20%;
    top: -355px;
    filter: blur(50px);
}
.common-background-blue-eclipse {
    position: absolute;
    z-index: -1;
    filter: blur(50px);
    bottom: -450px;
    left: -620.2293701171875px;
    width: 954px;
    height: 979px;
}
@media only screen and (max-width: 800px) {
    .common-background-red-eclipse {
        right: -100px;
        top: -100px;
        width: 450px;
        height: auto;
    }
    .common-background-blue-eclipse {
        bottom: -100px;
        left: -100px;
        width: 450px;
        height: auto;
    }
}
@media only screen and (max-width: 600px) {
    .common-background-red-eclipse {
        right: -40px;
        top: -40px;
        height: 300px;
        width: auto;
    }
    .common-background-blue-eclipse {
        bottom: -40px;
        left: -50px;
        height: 300px;
        width: auto;
    }
}
