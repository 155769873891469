.root {
    display: "flex";
    justify-content: "center";
    margin-top: 20px;
}

.leftGrid {
    padding: 20px !important;
}

.rightGrid {
    padding: 20px !important;
}

.typographyTitle {
    margin-bottom: 40px !important;
    font-weight: bold !important;
}

.chipRow {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.searchBar {
    margin-bottom: 50px
}

.list {
    max-height: 300;
    overflow: auto;
}

.sliderTitle {
    margin-bottom: 20px !important;
    font-size: 17px !important;
    font-weight: bold !important;
}

.slider {
    width: 400px;
}

.skillActionButton {
    border-radius: 0;
    width: 250px;
    height: 30px;
    margin-left: 30px;
  
    @media (min-width: 600px) {
      width: 510px;
      margin-left: 30px;
    }
  
    @media (min-width: 960px) {
      width: 150px;
      margin-left: 30px;
    }
  
    @media (min-width: 1280px) {
      width: 30px;
    }
  }