.title {
  flex-grow: 1;
  text-align: left auto;
  color: #000000 !important;
  opacity: 1;
  font-size: 31px;
  font-weight: bold;
  display: flex;
}

.shimmer-thumbnail-no-margin-bottom {
  margin-bottom: 0;
}
