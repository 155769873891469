.modal-title{
  flex-grow: 1;
  text-align: left;
  color: #0F072C !important;
  padding-left: 40px !important;
  padding-top: 25px;
  padding-right: 24px !important;
  padding-bottom: 0 !important;
  font-size: 1.7em;
  font-weight: bold;
}
.modal-sub-title{
  text-align: left;
  color: #6F6A80 !important;
  padding-left: 40px !important;
  padding-right: 24px !important;
  padding-bottom: 0 !important;
  font-size: 1em;
}
.modal-info{
  text-align: left;
  color: #6F6A80 !important;
  font-size: 1em;
}
.modal-body{
  padding-top: 0px !important;
  padding-left: 19px !important;
  padding-right: 19px !important;
  padding-bottom: 19px !important;
  width: 100%;
}
.modal-title-container{
  display: flex; 
}
.modal-subtitle-container{
  display: flex; 
  margin-bottom: 24px;
}
.modal-info-container{
  margin-bottom: 20px;
  margin-left: 40px !important;
}
