.form2{
    background-color: #FFFFFF;
    width: 100%;
    max-width: 660px;
    height: 100%;
    max-height: auto;
    padding: 40px;
    font-weight: bold !important;
  }
.form-title{
  padding-bottom: 40px;
  font-weight: bold !important;
}