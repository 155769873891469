.accepted-filetypes {
  font-size: small;
  color: #979595;
  display: block;
}
.allowed-filesize {
  font-size: small;
  color: #979595;
  display: block;
  margin-bottom: 1.5rem;
}
.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Vertically center items */
  align-items: center; /* Horizontally center items */
  height: 250px;
}
.dropzone-icon {
  font-size: 48px !important;
}
.dropzone-hover {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Vertically center items */
  align-items: center; /* Horizontally center items */
  height: 250px;
  border-color: #007bff; /* Change border color on hover */
  background-color: #f0f0f0; /* Change background color on hover */
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5); /* Add a subtle shadow on hover */
}