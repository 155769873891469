.uploadButton {
  color: white;
  display: inline-block;
  background: #2684ff;
  border: none;
  padding: 9px 40px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 11pt;
}

.uploadField{
  font-size: 11pt;
}