.root {
  flex-grow: 1;
  padding: 1rem 0 1rem 0;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 27rem;
  height: 13rem;
  margin: 1rem;
  background-color: #F8F9FF !important;
}

.title {
  font-weight: bold;
  padding: 0.85rem 1.5rem 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
}

.editButton {
  padding: 0.5rem;
}

.content {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.view-more {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.divider {
  width: 100%;
  border-top: 0.0625rem solid #ccc;
}