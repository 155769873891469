.pagination-ul {
  .MuiPagination-ul {
    position: relative;
  }
  &.MuiButtonBase-root {
    &.MuiPaginationItem-root {
      &.MuiPaginationItem-sizeSmall {
        color: red;
      }
    }
  }
}
