.viewSalaryHistoryPopup {

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.2rem;
  }

  .header Button {
    display: flex;
    justify-content: flex-end;
  }

  .modalTitle {
    font-size: 1em;
    flex-grow: 1;
    color: #0f072c !important;
    font-size: 1.7em;
    font-weight: bold;
  }

  .username {
    color: #2684ff;
    font-weight: 600;
    font-size: 1.2em;
  }

  .activeStatus {
    color: rgba(75, 181, 67, 1) !important;
    background-color: rgba(75, 181, 67, 0.3) !important;
    padding: 0.5rem;
    height: 100%;
  }

  .inactiveStatus {
    color: rgba(207, 205, 213, 1) !important;
    background-color: rgba(230, 229, 233, 1) !important;
    padding: 0.5rem;
    height: 100%;
    width: fit-content;
  }

  .lowOpacity {
    opacity: 0.8;
  }
}
